import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Input from "src/components/form/Input";
import { classNames } from "src/helpers/classNames";

const PasswordValidationItem = ({ isWaiting = true, condition, title }) => (
  <div className="ml-3 mt-2 flex items-center">
    {isWaiting ? <XMarkIcon className="mr-1 h-5 w-5 text-gray-400" /> : condition ? <CheckIcon className="mr-1 h-5 w-5 text-green-500" /> : <XMarkIcon className="mr-1 h-5 w-5 text-red-500" />}

    <span className={classNames("text-[14px], font-normal", isWaiting ? "text-gray-400" : condition ? "text-green-500" : "text-red-500")}>{title}</span>
  </div>
);

const ResetPasswordForm = ({ password = "", setPassword = () => {}, confirmPassword = "", setConfirmPassword = () => {} }) => {
  return (
    <>
      <div className="mb-4 grid gap-y-4">
        <div className="w-full">
          <Input
            inputValue={password}
            label={"Password *"}
            inputType={"password"}
            inputName={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={password.length >= 8}
            title={"At least 8 characters"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[a-z]/.test(password)}
            title={"Contains one lowercase letter"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[A-Z]/.test(password)}
            title={"Contains one uppercase letter"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[0-9]/.test(password)}
            title={"Contains one number"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[^\w\s]/.test(password)}
            title={"Contains one special character (@, #, $, %, etc.)"}
          />
        </div>
        <div className="w-full">
          <Input
            inputValue={confirmPassword}
            label={"Confirm Password *"}
            inputType={"password"}
            inputName={"password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <PasswordValidationItem
            isWaiting={confirmPassword === ""}
            condition={password === confirmPassword}
            title={"Passwords match"}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
