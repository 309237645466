import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import ContentError from "src/components/embedTypes/ContentError";
import ContentLoading from "src/components/embedTypes/ContentLoading";
import ContentTypesRouter from "src/components/embedTypes/ContentTypesRouter";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Dashboard = () => {
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const navigate = useNavigate();

  const { group, page } = useUrlQueryParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);

  const pageErrorTypes = {
    page_error: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "Something went wrong, please try later",
    },
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
    incorrect_filters: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the filters that were added were invalid or not filled out.",
    },
  };

  useEffect(() => {
    const handlePageGet = async () => {
      try {
        setContentError(null);
        setLoadingPage(true);
        const { data: contentResponseData } = await apiRequest("GET", `/portal/embed/:workspace_id`, { params: { workspace_id: workspaceDetails?.slug || workspaceDetails?._id }, queries: { group, page } });
        let { data } = contentResponseData;
        if (data?.invalidWorkspace) {
          navigate("/permission-denied");
        } else if (data?.invalidPage || data?.invalidGroup) {
          const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceDetails?._id}/page`);

          if (resData.status === 200) {
            navigate(resData.link);
            return;
          }
        } else if (data?.invalidFilter) {
          setContentError("incorrect_filters");
        } else {
          setContent(data);
          setLoadingPage(false);
        }
      } catch (error) {
        setLoadingPage(false);
        setContentError("page_error");
      }
    };

    if (workspaceDetails?._id && (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" || page)) {
      handlePageGet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, page, workspaceDetails?._id]);

  return (
    <>
      {contentError ? (
        <ContentError
          pageErrorTypes={pageErrorTypes}
          pageError={contentError}
        />
      ) : (
        <>
          {loadingPage ? (
            <ContentLoading menuType={menuType} />
          ) : (
            <div className="w-full">
              <ContentTypesRouter
                content={content}
                menuType={menuType}
                workspace={workspaceDetails}
                site={site}
                setError={() => {}}
                setLoading={() => {}}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
