import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/form/Button";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { SiteContext } from "src/context/site";
import AuthDataLayout from "src/layouts/AuthDataLayout";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordUrlInvalid from "./ResetPasswordUrlInvalid";

const ResetPassword = ({ type = "welcome-mail" }) => {
  const navigate = useNavigate();
  const { token } = useParams();

  const { loading: siteLoading, site } = useContext(SiteContext);

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordValidation = !password || !confirmPassword || password !== confirmPassword || !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[^\s]{8,}$/.test(password);

  const saveTokenAndLogin = (resData) => {
    localStorage.setItem("accessToken", resData.access_token || resData.accessToken);
    localStorage.setItem("refreshToken", resData.refresh_token || resData.refreshToken);

    window.location.reload();
  };

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true);

      try {
        const { data: resData } = await apiRequest("post", "/portal/verify-token", { body: { token } });

        if (resData.status === 200) {
          setVerified(true);

          setPassword("");
          setConfirmPassword("");
        } else {
          setVerified(false);
        }

        setLoading(false);
      } catch (err) {
        setVerified(false);

        setLoading(false);
      } finally {
      }
    };

    verifyToken();
  }, [token, type]);

  const onSubmit = async () => {
    setDisabled(true);

    try {
      const { data: resData } = await apiRequest("post", "/portal/change-password", {
        body: {
          password,
          confirm_password: confirmPassword,
          token,
        },
      });

      if (resData.status === 200) {
        toast.success(resData.message);

        if (type === "welcome-mail") {
          saveTokenAndLogin(resData);
        } else {
          navigate("/");
        }
      } else {
        toast.error(resData.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisabled(false);
    }
  };

  if (loading || siteLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Preloader />
      </div>
    );
  }

  const LinkUpComponent = () => (
    <div className="mt-4">
      <div className="flex justify-center space-x-2 font-medium">
        <span>Return to</span>
        <Button
          buttonClasses={"!px-0 !h-auto !bg-transparent !rounded-none"}
          buttonHasLink={true}
          buttonLink={"/"}
          buttonLabel={"Login page"}
          buttonLabelClasses={"hover:!underline !text-highlightColor"}
        />
      </div>
    </div>
  );

  return (
    <>
      {!loading && !verified ? (
        <ResetPasswordUrlInvalid url="/forgot-password" />
      ) : (
        <AuthDataLayout
          title="Create a new password"
          subTitle="Enter your new password and let's get the ball rolling!"
          LinkUpComponent={LinkUpComponent}>
          <form
            className="flex w-full flex-col"
            id="loginform">
            <ResetPasswordForm
              password={password}
              confirmPassword={confirmPassword}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
            />
            <div className="mt-2 grid gap-y-4">
              <Button
                isDisable={disabled || passwordValidation}
                buttonClasses={"w-full"}
                buttonStyles={{
                  borderColor: site?.highlight_color,
                  backgroundColor: site?.highlight_color,
                }}
                buttonType={"submit"}
                buttonLabel={type === "welcome-mail" ? "Create New Account" : "Reset Password"}
                buttonLabelClasses={""}
                buttonFunction={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            </div>
          </form>
        </AuthDataLayout>
      )}
    </>
  );
};

export default ResetPassword;
